/* eslint-disable no-unused-vars */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import SearchSection from '.';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useContext } from 'react';
import { AuthContext } from 'context/UserContext';
import { useEffect } from 'react';
import { Avatar, Button, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(237, 231, 246, 0.0)',
        maxWidth: 1000
    }
}));

function SearchBar() {
    const [showTooltip, setShowTooltip] = useState(false);
    const { students, setStudent } = useContext(AuthContext);

    useEffect(() => {
        if (students.length > 1) {
            setShowTooltip(true);
        }
        if (students.length < 1) {
            setShowTooltip(false);
        }
    }, [students]);


    const handleSelect = (student) => {
        setShowTooltip(false);
        setStudent(student);
    };

    return (
        <>
            <Box>
                <Box>
                    <SearchSection />
                </Box>
                {showTooltip && (
                    <Box sx={{ position: 'absolute', left: '50%', transform: 'translateY(20%)' }}>
                        <HtmlTooltip
                            open={showTooltip}
                            title={
                                <Box sx={{
                                    overflowY: 'auto',
                                    height: '88vh',
                                }}>
                                    {students?.map((student, i) => (
                                        <div
                                            key={i}
                                            style={{
                                                // maxWidth: '28rem',
                                                width: '30rem',
                                                backgroundColor: 'white',
                                                boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.12)',
                                                borderRadius: '0.5rem',
                                                pointerEvents: 'auto',
                                                display: 'flex',
                                                margin: '0.6rem',
                                                border: '1px solid rgba(0, 0, 0, 0.1)'
                                            }}
                                        >
                                            <div style={{ flex: '1', width: '0', padding: '1rem' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ paddingTop: '0.125rem' }}>
                                                        <Avatar
                                                            style={{ height: '2.5rem', width: '2.5rem', borderRadius: '9999px' }}
                                                            src={`${student.StudentPhotoURL ? student.StudentPhotoURL : '/broken-image.jpg'}`}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div style={{ marginLeft: '0.75rem', flex: '1' }}>
                                                        <p style={{ fontSize: '1rem', fontWeight: '500', color: '#1a202c', margin: '0' }}>{student.StudentName}</p>
                                                        <p style={{ fontSize: '0.875rem', color: '#4a5568', margin: '0' }}>
                                                            {student.Class} || Index:{student.Index} || Id:{student.StudentId}
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', borderLeft: '1px solid rgba(0, 0, 0, 0.1)' }}>
                                                <Button
                                                    color="secondary"
                                                    size="medium"
                                                    startIcon={<CheckIcon />}
                                                    onClick={() => handleSelect(student)}
                                                >
                                                    SELECT
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                    {/* <Box /> */}
                                </Box>
                            }
                        >
                            <span color="#ff7373">!</span>
                        </HtmlTooltip>
                    </Box>
                )}
            </Box>
        </>
    );
}

export default SearchBar;
