import axios from 'axios';

const defaultBaseURL =
    window.location.hostname === 'localhost'
        ? 'http://localhost:3131/'
        : 'https://chs-server.vercel.app/';
const API = axios.create({
    baseURL: defaultBaseURL,
    headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('authToken'),
        email: localStorage.getItem('email'),
        year: localStorage.getItem('year'),
    },
});

export default API;
